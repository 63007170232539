<template>
  <div class="mobile-container">
    <div class="user-info-item img-cover">
      <img src="../../assets/images/website-bg.png" />
      <div class="back-item img-cover" @click="$router.go(-1)"><img src="../../assets/images/back-icon-black.png" /></div>
      <div class="user-info">
        <div class="header-item img-cover">
          <img v-if="userInfo.Header" :src="userInfo.Header" />
          <img v-else src="../../assets/images/default-header.png" />
        </div>
        <div class="info">
          <div class="name">
            <span v-if="userInfo.Nickname">{{ userInfo.Nickname }}</span>
            <span v-else>{{ userInfo.Phone | phoneFormat }}</span>
          </div>
          <div class="level" :class="'level-' + userInfo.FzLevel">{{ userInfo.FName || '您还未创建分站' }}</div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <van-swipe class="swipe-container" type="card" :loop="false" style="height: 100%;" @change="handleSwipeChange">
        <van-swipe-item v-for="(item, index) in vipList" :key="index">
          <div class="vip-item" :class="swipeIndex == index ? 'active' : ''">
            <div class="vip-info">
              <div class="icon img-cover"><img :src="item.Banner" /></div>
              <div class="detail" :class="'level-' + item.Level">
                <div class="label">{{ item.Name }}</div>
                <div class="money-item"><span>{{ item.Money }}</span>元 丨 永久使用</div>
              </div>
            </div>
            <div class="title-item">{{ item.Name }}权益</div>
            <div class="auth-list-item">
              <div v-for="(auth, authIndex) in authList" :key="authIndex"  class="auth-item">
                <div class="icon img-cover">
                  <img v-if="auth['Vip' + item.Level] == 1" src="../../assets/images/selected-icon.png" />
                  <img v-else src="../../assets/images/selected-not-icon.png" />
                </div>
                <div class="auth">{{ auth.Title }}</div>
              </div>
            </div>
            <template v-if="item.Level > userInfo.FzLevel">
              <div v-if="userInfo.FzLevel" class="btn-item public-btn" @click="handleShowUpgrade(item.Level)">升级分站</div>
              <router-link v-else class="btn-item public-btn" :to="{path: '/buildSiteSubmit', query: {level: item.Level}}">立即建站}</router-link>
            </template>
            <div v-else class="btn-item public-not-btn">已创建{{ userInfo.FName }}，升级更高的分站吧</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="footer-item"></div>
    <van-popup v-model="upgradeData.show" position="center" style="border-radius: .16rem;">
      <div class="mobile-refund-order-container">
        <div class="title">升级分站</div>
        <div class="detail">确定要升级分站吗？</div>
        <div class="btn-item">
          <div class="btn" @click="upgradeData.show = false">取消</div>
          <div class="btn sure" @click="handleUpgradeSite">
            <van-loading v-if="upgradeData.submiting" color="#ffffff" />
            <span v-else>确定</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "BuildSite",
  data() {
    return {
      swipeIndex: 0,
      vipList: [],
      authList: [],
      upgradeData: {
        show: false,
        submiting: false,
        Level: ''
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    phoneFormat(text) {
      if (text) {
        return text.substr(0, 3) + '****' + text.substr(-4)
      }
      return ''
    }
  },
  watch: {
    
  },
  mounted() {
    this.handleGetAuthList()
    this.handleGetVipList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleSwipeChange(val) {
      this.swipeIndex = val
    },
    async handleGetAuthList() {
      var res = await this.$API.substationsAuthList.post()
      this.authList = res
    },
    async handleGetVipList() {
      var res = await this.$API.vipMoney.post({
        Type: 2
      })
      if (res && res.length > 0) {
        this.vipList = res
      }
    },
    handleShowUpgrade(level) {
      this.upgradeData = {
        show: true,
        submiting: false,
        Level: level
      }
    },
    async handleUpgradeSite() {
      const { upgradeData } = this
      if (upgradeData.submiting) {
        return
      }
      this.upgradeData.submiting = true
      var res = await this.$API.buySubstations.post({
        Level: upgradeData.Level
      })
      this.upgradeData.submiting = false
      this.upgradeData.show = false
      if (res.error == 0) {
        this.GetUserInfo()
        this.$toast.success(res.info)
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.user-info-item {
  width: 100%;
  height: 5.2rem;
  position: relative;
  .back-item {
    cursor: pointer;
    position: absolute;
    left: .14rem;
    top: .14rem;
    width: .6rem;
    height: .6rem;
  }
  .user-info {
    position: absolute;
    top: .88rem;
    left: 0;
    right: 0;
    padding: .2rem .32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-item {
      width: 1.2rem;
      height: 1.2rem;
      border: .02rem solid #fff;
      border-radius: 50%;
      overflow: hidden;
    }
    .info {
      flex: 1;
      margin-left: .24rem;
      .name {
        line-height: .48rem;
        color: #11113a;
        font-weight: bold;
        font-size: .32rem;
      }
      .level {
        display: inline-block;
        margin-top: .08rem;
        height: .32rem;
        line-height: .32rem;
        padding: 0 .12rem;
        border-radius: .06rem;
        background-color: #fff;
        font-size: .2rem;
        color: #3f3f3f;
      }
    }
  }
}
.page-content {
  margin-top: -2.88rem;
  margin-bottom: -2rem;
  flex: 1;
  width: var(--max-width);
  overflow: hidden;
  position: relative;
  .swipe-container {
    /deep/.van-swipe-item--card {
      width: var(--max-width-13vw) !important;
    }
  }
  .vip-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    &.active {
      .title-item, .auth-list-item, .btn-item{
        opacity: 1;
      }
    }
    .vip-info {
      width: 100%;
      height: 1.84rem;
      padding-top: .36rem;
      position: relative;
      .icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 2rem;
        height: 2.2rem;
      }
      .detail {
        width: 100%;
        height: 1.84rem;
        border-radius: .24rem;
        .label {
          display: inline-block;
          padding: 0 .24rem;
          height: .36rem;
          line-height: .36rem;
          color: #fff;
          font-size: .2rem;
          border-radius: .24rem 0 .24rem 0;
        }
        .money-item {
          margin-top: .2rem;
          margin-left: .24rem;
          font-weight: 500;
          font-size: .24rem;
          span {
            font-size: .72rem;
            font-weight: 900;
          }
        }
      }
    }
    .title-item {
      opacity: 0;
      width: 100%;
      padding: .22rem 0;
      line-height: .48rem;
      font-size: .32rem;
      color: #242941;
      font-weight: bold;
    }
    .auth-list-item {
      opacity: 0;
      flex: 1;
      overflow-y: auto;
      .auth-item {
        float: left;
        width: 50%;
        padding: .22rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: .36rem;
          height: .36rem;
        }
        .auth {
          margin-left: .12rem;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #242941;
          font-size: .28rem;
        }
      }
    }
    .btn-item {
      cursor: pointer;
      opacity: 0;
      width: 100%;
      height: .88rem;
      margin: .32rem 0 .8rem;
      font-size: .3rem;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: .88rem;
      border-radius: .12rem;
      &.public-not-btn {
        background: linear-gradient( 135deg, rgba(88, 183, 255, .6) 0%, rgba(19, 153, 255, .6) 100%);
        box-shadow: 0 .06rem .12rem .02rem rgba(88, 183, 255, .18);
      }
    }
  }
}
.footer-item {
  width: 100%;
  height: 2rem;
  background-color: #fff;
}
.level-50 {
  color: #3f3f3f !important;
  background: linear-gradient( 90deg, #ffffff 0%, #cbcbcb 100%);
  .label {
    background: linear-gradient( 121deg, #777678 0%, #3c3b3c 100%);
  }
}
.level-51 {
  color: #372506 !important;
  background: linear-gradient( 134deg, #fffdf8 0%, #ffc768 75%, #fff3e4 100%);
  .label {
    background-color: #f0c47f;
  }
}
.level-52 {
  color: #ebd29a !important;
  background: linear-gradient( 134deg, #221d11 0%, #0f0b08 75%, #403723 100%);
  .label {
    background: linear-gradient( 90deg, #94713b 0%, #c4a76a 100%);
  }
}
.mobile-refund-order-container {
  width: 5rem;
  padding: .4rem;
  text-align: center;
  .title {
    line-height: .48rem;
    color: #11113a;
    font-size: .32rem;
    font-weight: bold;
  }
  .detail {
    margin-top: .28rem;
    color: #bcbdc5;
    line-height: .4rem;
    font-size: .28rem;
  }
  .btn-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .72rem;
    .btn {
      width: 2.4rem;
      height: .8rem;
      border-radius: .12rem;
      background-color: #bac0cc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: .24rem;
      &.sure {
        background-color: #276eff;
      }
    }
  }
}
</style>